import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';
import dog1 from "./dog.png";
import dog2 from "./dog1.jpg";
import dog3 from "./dog2.jpg";
import dog4 from "./dog3.jpg";
import dog5 from "./dog4.jpg";

function App() {
  const [current, setCurrent] = useState(0);
  const images = [dog1, dog2, dog3, dog4, dog5];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((prevCurrent) => (prevCurrent + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="App">
      🌞안녕하세요!🌞
      <br></br>
      AWS 도메인을 사용하여 만든 조연진의 개인 사이트입니다! <br></br>
      이 페이지에 들려 주셔서 감사합니다~ 이 자리를 빌어..<br></br>
      우리집 강아지를 소개합니다😁
      <hr></hr>
      <br></br>
      {/* <img src="/dog.png" style={{width:"30%"}}/> */}
      <div className='show'>
        {/* 이미지 슬라이드 넣을 부분 */}
        <img src={images[current]} alt="우리집 강아지" />
      </div>
      <p className='text-box'>
        이름 : 연근<br></br>
      나이 : 3살 추정<br></br>
      유기견 보호소 출신으로 2살의 나이에 처음으로 사람과 함께 집에서 살게 되었습니다.<br></br>
      처음엔 세상 모든 것이 무서워 바들바들 떨기도 했었지만, 지금은 산책이 제일 좋은 씩씩한 강아지가 되었답니다 🐶
      </p>
    </div>
  );
}

export default App;
